import type { ShouldRevalidateFunction } from "@remix-run/react";
import React from "react";
import { LoginForm } from "~common/components/Login/LoginForm.tsx";
import { ZipDealTextSpan } from "~zd-ui";

export { authenticationRedirectionAction as action } from "@/server/actions/authenticationRedirectionAction.server.ts";

const OnboardingLogin = () => {
  return (
    <LoginForm
      title={
        <div className={""}>
          Login to <ZipDealTextSpan /> Onboarding
        </div>
      }
    />
  );
};

export default OnboardingLogin;

export const shouldRevalidate: ShouldRevalidateFunction = ({ formData, formMethod }) => {
  return !!formData && formMethod !== "GET";
};
